import './App.css';
import { useRoutes } from 'react-router-dom';
import routes from './routes';
import Header from './components/Header';
import Footer from './components/footer/Footer';

function App() {
  const showRoutes = useRoutes(routes);

  return (
    <>
    <div>asdsadsad</div>
      <div className="App">
        <Header />
        {showRoutes}
      </div>
      <Footer />
    </>
  );
}
export default App;
