import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { addPost, getCategories, updateProduct } from "../../store/productSlice";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

export default function ProductForm({ title, data }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const categories = useSelector(state => state.product.categories);
    const [product, setProduct] = useState({ name: '', price: '', description: '', categoryId: ''});
    const [value, setValue] = useState('');
    const inputOnChange = (e) => {
        setProduct({ ...product, [e.target.name]: e.target.value });
    }
    const onSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', product.name);
        formData.append('price', product.price);
        formData.append('description', value);
        formData.append('categoryId', product.categoryId);
        //formData.append('userId', product.userId);
        formData.append('img', product.img);
        console.log(product);

        if (data) {
            formData.append('productId', product.productId);
            dispatch(updateProduct({ formData, product }));
        } else {
            dispatch(addPost(formData));
        }
        document.querySelector('.container-form').style.filter = 'opacity(0.5)'
        setTimeout(() => {
            navigate('/admin_product');
        }, 1000)
        
    }
    useEffect(() => {
        dispatch(getCategories());
        if (data) {
            console.log(data);
            setProduct({ productId: data.id, name: data.name, price: data.price, img: data.imgUrl, categoryId: data.categoryId, userId: data.userId });
            setValue(data.description);
        }
    }, [data])
    return (
        <div className="container-form" id="form-product">
            <div>
                <h2>{title}</h2>
            </div>
            <form className="form" onSubmit={onSubmit}>
                <div>
                    <label>Name</label>
                    <input type="text" onChange={inputOnChange} name='name' value={product.name}></input>
                </div>
                <div>
                    <label>Price</label>
                    <input type="text" onChange={inputOnChange} name='price' value={product.price}></input>
                </div>
                <div>
                    <label>İmage Url</label>
                    <input type="file" name='img'  onChange={(e) => { setProduct({ ...product, img: e.target.files[0] }) }}></input>
                </div>
                <div>
                    <label>Category</label>
                    <select onChange={inputOnChange} name='categoryId' value={product.categoryId}>
                        <option></option>
                        {
                            categories.map(category => {
                                return (
                                    <option value={category.id}>{category.name}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <div>
                    <label>Description</label>
                    <ReactQuill theme="snow" value={value} onChange={setValue} />
                </div>
                <button type="submit">{title}</button>
            </form>
        </div>
    )
}