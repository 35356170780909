import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { useParams } from 'react-router-dom';
import { getProductById } from "../../store/productSlice";
import { addToCard } from "../../store/shopSlice";
import './detail.css';
import imageZoom from "./ImageZomm";
export default function ProductDetail() {
    const baseUrl = useSelector(state => state.auth.api);
    const dispatch = useDispatch();
    const { productId } = useParams();
    const productDetail = useSelector(state => state.product.productDetail);
    const onButtonClick = () => {
        dispatch(addToCard(productDetail));
    }
    imageZoom("detail-img", "myresult");
    const onMouseİmg = (e) => {
        const result = document.getElementById("myresult");
        result.classList.toggle("show");
    }
    useEffect(() => {
        dispatch(getProductById(productId));
        console.log(productDetail);
    }, [])
    return (
        <div className="detail">
            <div className="img-container">
                <img id="detail-img" onHo={onMouseİmg} src={`${productDetail.imgUrl}`}></img>
                <div id="lens" className="img-zoom-lens"></div>
            </div>
            <div id="myresult" class="img-zoom-result"></div>
            <div className="detail-content-container">
                <div className="detail-content">
                    <h2>{productDetail.name}</h2>
                    <div className="detail-price">{productDetail.price}</div>
                    <button onClick={onButtonClick}>Add To Cart</button>
                    <p dangerouslySetInnerHTML={{ __html: productDetail.description }} />
                </div>
            </div>
        </div>
    )
}