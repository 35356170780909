import axios from "axios";
import { useState } from "react"

export default function PaymentForm() {
    const [data, setData] = useState({ name: '', surname: '', gsmNumber: '', email: '', city: '', address: '' });
    const inputOnChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    }
    const onSubmit = (e) => {
        e.preventDefault();
            axios.post('http://localhost:8000/pay',data, { withCredentials: true })
            .then(result => {
              let frag = document.createRange().createContextualFragment(result.data);
              console.log(frag);
             document.head.appendChild(frag);
             }).catch(err => {
               console.log(err);
            })
    }
    return (
        <div className="container-form" id="form-payment">
            <div>
                <h2>Payment</h2>
            </div>
            <form className="form" onSubmit={onSubmit}>
                <div>
                    <label>Name</label>
                    <input type="text" onChange={inputOnChange} name='name' value={data.name}></input>
                </div>
                <div>
                    <label>Surname</label>
                    <input type="text" onChange={inputOnChange} name='surname' value={data.surname}></input>
                </div>
                <div>
                    <label>Gsm Number</label>
                    <input type="text" onChange={inputOnChange} name='gsmNumber' value={data.gsmNumber}></input>
                </div>
                <div>
                    <label>Email</label>
                    <input type="text" onChange={inputOnChange} name='email' value={data.email}></input>
                </div>
                <div>
                    <label>City</label>
                    <input type="text" onChange={inputOnChange} name='city' value={data.city}></input>
                </div>
                <div>
                    <label>Adress</label>
                    <textarea  onChange={inputOnChange} name='address' rows="5" value={data.address}></textarea>
                </div>
                <button type="submit">pay</button>
            </form>
        </div>
    )
}