import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from '../utility/api';
import axios from 'axios';

const initialState = {
    isAuthenticated: localStorage.getItem('accessToken') ? true : false,
    userId: null,
    message: null,
    api:"http://localhost:8000"
}

export const Register = createAsyncThunk('Register', async (data) => {
    try {
        const response = await api().post('/register', data
        ,{withCredentials:false}
        );
        console.log(response.data);
        return response.data;
    } catch (err) {
        console.log(err);
    }
});

export const Login = createAsyncThunk('Login', async (data) => {
    try {
       // axios.defaults.withCredentials = true;
        const response = await axios.post('https://65d8ovfcsf.execute-api.us-east-1.amazonaws.com/dev/login', data
      //  ,{withCredentials:true}
        //,{
    //     headers: {
    //       'Access-Control-Allow-Origin': 'http://localhost:3000'
    //     }
      //}
        );
        console.log(response.data);
        return response.data;
    } catch (err) {
        console.log(err);
    }
})

const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            localStorage.clear();
            state.isAuthenticated = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(Login.fulfilled, (state, action) => {
            if (action.payload.token) {
                state.isAuthenticated = true;
                state.userId = action.payload.userId;
                localStorage.setItem('accessToken', action.payload.token);
                localStorage.setItem('userId', action.payload.userId);
            } else {
                state.message = action.payload.message;
            }
        })
    }
})
export default auth.reducer;
export const { logout } = auth.actions;