import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { api } from "../utility/api"
import AuthHeader from "../utility/AuthHeader";
const initialState = {
    cart: [],
    orders: [],
    loading: false,
    err: null
}

export const getCart = createAsyncThunk('getCart', async (userId) => {
    try {
        const response = await api().get(`/basket`, { withCredentials: true });
        return response.data;
    } catch (err) {
        console.log(err);
    }
})

export const deleteCartItem = createAsyncThunk('deleteCartItem', async (data) => {
    try {
        const response = await api().delete(`/basket/${data.basketId}/${data.productId}`, { withCredentials: true });
        return data.productId;
    } catch (err) {
        console.log(err);
    }
})
// export const addToCart = createAsyncThunk('addToCart', async (data) => {
//     try {
//         const response = await api().post('/basket', {productId:data.productId}, { withCredentials: true });
//         return data;
//     } catch (err) {
//         console.log(err);
//     }
// })

export const addToOrder = createAsyncThunk('addToOrder', async (userId) => {
    try {
        const response = await api().post('/order', userId, { headers: AuthHeader() });
        return response.data;
    } catch (err) {
        console.log(err);
    }
})
export const getOrders = createAsyncThunk('getOrders', async (userId) => {
    try {
        const response = await api().get(`/order`, { withCredentials: true });
        return response.data;
    } catch (err) {
        console.log(err);
    }
})

const shop = createSlice({
    name: 'shop',
    initialState,
    reducers: {
        addToCard: (state, action) => {
            let item=action.payload;
            let card = JSON.parse(localStorage.getItem("card"));
            if (!card) {
                localStorage.setItem("card", JSON.stringify([]))
                card = [];
            }

            const index = card.findIndex(cartproduct => {
                return cartproduct.id === item.id;
            });
        
            const updatedCart = [...card];
            let itemQuantity = 1;
            if (index >= 0) {
                itemQuantity = card[index].quantity + 1;
                updatedCart[index].quantity = itemQuantity;
            } else {
                updatedCart.push({...item,quantity:itemQuantity});
            }

            // const newCard = card;
            // newCard.push(action.payload);
            localStorage.setItem("card", JSON.stringify(updatedCart));
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getCart.fulfilled, (state, action) => {
            state.cart = action.payload.products;
        })
        builder.addCase(deleteCartItem.fulfilled, (state, action) => {
            state.cart = state.cart.filter(item => item.id !== action.payload);
        })
        // builder.addCase(addToCart.fulfilled, (state, action) => {
        //     state.cart.push(action.payload);
        // })
        builder.addCase(getOrders.fulfilled, (state, action) => {
            state.orders = action.payload.orders;
        })
        builder.addCase(addToOrder.fulfilled, (state, action) => {
            state.orders.push(action.payload.order);
        })
    }
})
export default shop.reducer;
export const { addToCard } = shop.actions;